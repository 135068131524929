import { UsersService } from './../../shared/services/users.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  form : FormGroup;

  constructor(private userService: UsersService,private snackBar: MatSnackBar) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName : new FormControl('', [Validators.required]),
    })
  }

  ngOnInit(): void {
  }

  onCreate(){
    let config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = "green-snackbar"
    this.userService.register(this.form.value).subscribe(
      (res:any)=>{
        if(res){
          this.snackBar.open("User successfully added !", '', config);
        }
      }
    )
  }
}
