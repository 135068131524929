import { AuthComponent } from './auth/auth.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageMenuComponent } from './image-menu/image-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InterceptorService } from '../shared/loader/interceptor.service';
import {Component} from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';


import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AuthGuard } from '../shared/guards/auth.guard';


import { RouterModule } from '@angular/router';






import {   ReactiveFormsModule, FormsModule  } from "@angular/forms";



import {MatTableModule } from '@angular/material/table';


import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field'; 
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatDialog} from '@angular/material/dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import {  } from '@angular/material/table';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 

import { NotificationService } from '../shared/services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrollingModule } from '@angular/cdk/scrolling';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ImageMenuPageComponent } from './image-menu-page/image-menu-page.component';



import {MatSliderModule} from '@angular/material/slider';
import { UsersComponent } from './users/users.component';











@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ImageMenuPageComponent,
    ImageMenuComponent,
    UsersComponent
  ],

  imports: [
    HttpClientModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSliderModule,
    

    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    FlexLayoutModule,
    BrowserAnimationsModule,

    MatAutocompleteModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ScrollingModule,
    MatProgressBarModule
    
    

    

  ],

  exports :[
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSliderModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    FlexLayoutModule,
    BrowserAnimationsModule,

    MatAutocompleteModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
  

    
    

  ],


  providers: [{provide : HTTP_INTERCEPTORS,useClass:InterceptorService, multi:true }, AuthGuard,  MatSnackBar,NotificationService,ImageMenuComponent],
  bootstrap: [AppComponent],
  entryComponents : []
})
export class AppModule { }
