import { UsersComponent } from './../users/users.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RolesGuard } from 'src/shared/guards/roles.guard';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-menu-page',
  templateUrl: './image-menu-page.component.html',
  styleUrls: ['./image-menu-page.component.scss']
})
export class ImageMenuPageComponent implements OnInit {

  canSee : boolean;
  role : string|null;

  constructor(private router:Router, public roleGuard:RolesGuard, public dialog:MatDialog) { 
    this.role = localStorage.getItem('isAdmin');
    this.canSee  = this.roleGuard.canActivate();
    console.log(this.role);
    
  }
  dialogRef!: MatDialogRef<UsersComponent>;
  ngOnInit(): void {
  }


  goToPage(routeId : string){
    this.router.navigate(['/'+routeId]);
  }

   

  openUserDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.width = "70%";
    this.dialog.open(UsersComponent, dialogConfig); 
  }

}
