<form class="normal-form" [formGroup]="form" (ngSubmit)="onCreate()">
    <div class="controles-container">

        <mat-form-field>
            <input formControlName="email"matInput placeholder= "Email" matNativeControl required>
        </mat-form-field>

        <mat-form-field>
            <input formControlName="password"matInput placeholder= "Password" matNativeControl required>
        </mat-form-field>

        <mat-form-field>
            <input formControlName="firstName"matInput placeholder= "First Name" matNativeControl required>
        </mat-form-field>

        <mat-form-field>
            <input formControlName="lastName"matInput placeholder= "Last Name" matNativeControl required>
        </mat-form-field>

        <div class="button-row">
            <button mat-raised-button color="primary" type="submit">Done</button>
        </div>
        
    </div>
</form>