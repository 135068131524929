import { baseUrl } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Users } from '../models/users.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http : HttpClient) { }
  register(data:any):Observable<any>{
    return this.http.post(`${baseUrl}users/register/`,data);
    
  }

  delete(email:any, password:any):Observable<any>{
    var data = {
      email : email,
      password : password
    }
    return this.http.post(`${baseUrl}users/delete`,data);
  }
  
  //SHOW DATA
  getUsers():Observable<Users[]>{
    return this.http.get<Users[]>(`http://localhost:8080/api/users/show`);
  }

  update(data: any, id:any):Observable<any>{
    var allData = {
      id : id,
      email : data.email,
      password : data.password,
      firstName : data.firstName,
      lastName : data.lastName,
      userType : data.userType,
    }
    return this.http.post(`${baseUrl}users/update/`,allData);
  }
  
  // TODO : Set precise validator to avoid any DB corruption !
}
