

<body>
    
    <header>
        <div >
            <h2 style="textAlign:center">
                For better user experience, please zoom out the page  
            </h2>
            <div class="main">
                <div class="sidebar">
                    <div>
                        <button mat-raised-button class="menu-button" class="user" (click)="openUserDialog()"><mat-icon>supervisor_account</mat-icon>Add User</button>
                    </div>
                </div>
                <div class="right-sidebar">
                    <div>
                        <button mat-raised-button [disabled]="false" class="menu-button" class="logout" (click)="goToPage('login')"><mat-icon>power_settings_new</mat-icon>  Log Out</button>    
                    </div>
                </div>
                <div style="height: 15%;"></div>
                <section><app-image-menu></app-image-menu></section>
            </div>
        </div>
    </header>
    

</body>




<!---->