import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {

  constructor(private authService:AuthService,private router : Router){
    
  }

  canActivate():boolean{
    var role = this.authService.isAdmin();
    console.log(role);
    if(role == "true" || role == "2"){
      return true;
    }else{
      return false;
    }
  }
}
