<!doctype html>
<div class="menu-holder">
    <div id="main" class="main" style="position:relative;">
        <h2 *ngIf="isThereImage" id="gamesense">
            Sens du jeu: <mat-icon style="vertical-align: middle;">{{this.directionIcon}}</mat-icon> ( {{this.direction}} ) 
        </h2>
        <input style="position:absolute;"*ngIf="!isThereImage" type="file" accept="image/" name="image" id="file" (change)="loadImage($event)">
        <canvas class="canvas" style="visibility: hidden;" #canvas (click)="this.pointPlacement($event)" ng-controller="canvasController"></canvas>
        <!--<img *ngIf="!isThereImage" id="output">-->
        
    </div>

    <div class ="sidebar">
        <div *ngIf="isThereImage">
            <div class="point-button-holder">
                <div>
                    <div class="direction-point-button">
                        <div class="point-button">
                            <button mat-raised-button (click)="this.changeDirection('left')"><mat-icon>arrow_left</mat-icon></button>
                        </div>
                    </div>
                    
                    <div class="direction-point-button">
                        <div class="point-button">
                            <button mat-raised-button (click)="this.changeDirection('right')"><mat-icon>arrow_right</mat-icon></button>
                        </div>
                    </div>
                   
                </div>

                <p>Points placement</p>

                <div class="point-button">
                    <button mat-raised-button (click)="selectPoint('Point1')">Point 1 </button>
                </div>
                <div class="point-button">
                    <button mat-raised-button (click)="selectPoint('Point2')">Point 2</button>
                </div>
                <div class="point-button">
                    <button mat-raised-button (click)="selectPoint('Point3')">Point 3</button>
                </div>
                <div class="point-button">
                    <button mat-raised-button (click)="selectPoint('Point4')">Point 4</button>
                </div>

                <div class="point-button">
                    <button mat-raised-button (click)="selectPoint('SidelinePoint1')">Sideline Point 1</button>
                </div>
                <div class="point-button">
                    <button mat-raised-button (click)="selectPoint('SidelinePoint2')">Sideline Point 2</button>
                </div>
                
                <div class="slider-holder">
                    <mat-slider #railSlider (input)="this.varLineMove($event)" onchange="" thumbLabel aria-label="units" [displayWith]="formatLabel" min= "0" max="100"></mat-slider>
                </div>
    
                <div class="point-button">
                    <button mat-raised-button (click)="this.undo()">Undo</button>
                </div>
                <br>
                <div class="point-button-holder">
                    <button mat-raised-button (click)="this.reset()">Restart</button>
                </div>
                <br>
                <div class="point-button">
                    <button mat-raised-button (click)="this.saveCanvas()">Save</button>
                </div>
                <button mat-raised-button>
                    <a *ngIf="isDownloadable" href="{{this.downloadURL}}" download="" rel="noopener noreferrer" target="_blank">
                        Download File
                    </a>
                </button>
            
            </div>
            
        </div>
    </div>

</div>
