<body>
    <div class="login-panel" fxLayoutAlign="center center" fxFlexFill>
        <mat-card fxFlex="25">
            <form fxLayoutAlign="stretch" fxLayout = "column" class="login-form" [formGroup]="loginFormGroup" (ngSubmit)="loginProcess()" *ngIf="!this.canLog">
                <mat-toolbar>Welcome to VAR-Assist</mat-toolbar>

                <mat-form-field >
                    <input matInput placeholder="Email" formControlName="email">
                </mat-form-field>

                <mat-form-field >
                    <input matInput type="password" placeholder="Password" formControlName="password">
                </mat-form-field>

                <br>
                <br>
                <button   [style.height.px]=50 mat-raised-button type="submit" class="mt5">Login</button>
            </form>
            
            <form fxLayoutAlign="stretch" fxLayout = "column" class="tfa-form" [formGroup]="tfaFormGroup" (ngSubmit)="tfaVerifyProcess()" *ngIf="this.canLog" >
                <mat-toolbar>Setup TFA</mat-toolbar>
                
                <img id="qrcode" src="{{myQR}}">
                <br>
                <p>Enter your secret password</p>
                <mat-form-field>
                    <input matInput type="password" placeholder="Secret Password" formControlName="userToken">
                </mat-form-field>

                <br>
                <br>
                <button mat-raised-button type="submit" class="mt5">Enter</button>
            </form>
        </mat-card>
    </div>

</body>