import { AuthService } from './../../shared/services/auth.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import { NotificationService } from 'src/shared/services/notification.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  
  loginFormGroup !: FormGroup;
  registerFormGroup !: FormGroup;
  tfaFormGroup !: FormGroup;
  canLog : boolean;
  tfaSucceed : boolean;
  myQR !: any;
  tfaToken !: string;
  
  

  constructor(private authService : AuthService, private router : Router,private snackBar : MatSnackBar,private notifService : NotificationService) {
    this.canLog = false;
    this.tfaSucceed = false;
    
  }

  ngOnInit(){
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    this.initForm();
  }

  initForm(){
    this.loginFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    })
    this.tfaFormGroup = new FormGroup({
      userToken: new FormControl('', [Validators.required]),
    })
  }

  loginProcess(){
    let successConfig = new MatSnackBarConfig();
    successConfig.panelClass = "green-snackBar";
    successConfig.duration = 2000;
    let errorConfig = new MatSnackBarConfig();
    errorConfig.panelClass = "red-snackBar";
    errorConfig.duration = 2000;
    if(this.loginFormGroup.valid){
      this.authService.login(this.loginFormGroup.value).subscribe(
        result=>{
          if(result.success === 1){
            localStorage.setItem('isAdmin' , result.userType);
            this.getQRCode();
            this.canLog = true;
            this.snackBar.open('Successfully logged in !', '', successConfig);
          }else{
            this.canLog = false;
            this.snackBar.open(result.error, '', errorConfig);
          }
        });
    }
  }

  getQRCode(){
    this.authService.genTFA().subscribe(
      result =>{
        if(result.qrcode){
          this.myQR = result.qrcode;
          console.log(result);
        }else{
          console.log(result);
        }
      }
    );
    
  }

  tfaVerifyProcess(){
    let successConfig = new MatSnackBarConfig();
    successConfig.panelClass = "green-snackBar";
    successConfig.duration = 2000;
    let errorConfig = new MatSnackBarConfig();
    errorConfig.panelClass = "red-snackBar";
    errorConfig.duration = 2000;
    if(this.tfaFormGroup.valid){
      this.authService.verTFA(this.tfaFormGroup.value).subscribe(
        result=>{
          if(result.success){
            this.snackBar.open('Successfully logged in !', '', successConfig);
            this.tfaSucceed = true;
            localStorage.setItem('token', result.token);
            this.router.navigate(['/image-menu']);
          }else{
            this.snackBar.open(result.error, '', errorConfig);
            this.tfaSucceed = false;
          }
        }
      )
    }
  }

 
  
}
