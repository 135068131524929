import { baseUrl } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http : HttpClient) { }

  login(data:any):Observable<any>{
    return this.http.post(`${baseUrl}users/login/`,data);
  }
  
  register(data:any):Observable<any>{
    return this.http.post(`${baseUrl}users/register/`,data);
  }


  genTFA():Observable<any>{
    return this.http.get(`${baseUrl}users/generate-tfa/`);
  }

  verTFA(data:any):Observable<any>{
    return this.http.post(`${baseUrl}users/verify-tfa/`, data);
  }

  isAdmin():string|null{
    var role =localStorage.getItem('isAdmin');
    return role;
  }

  loggedIn(){
    return !!localStorage.getItem('token');
  }

  
}
