import { ImageMenuPageComponent } from './image-menu-page/image-menu-page.component';
import { AuthComponent } from './auth/auth.component';
import { RolesGuard } from '../shared/guards/roles.guard';
import { AuthGuard } from '../shared/guards/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path:'', component : AuthComponent},
  {path : 'login', component:AuthComponent},
  {path:'image-menu', component : ImageMenuPageComponent, canActivate:[AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
