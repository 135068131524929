import { AfterViewInit,Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { Image } from 'src/shared/interfaces/image.interface';
import { Point } from 'src/shared/interfaces/point.interface';

@Component({
  selector: 'app-image-menu',
  templateUrl: './image-menu.component.html',
  styleUrls: ['./image-menu.component.scss']
})
export class ImageMenuComponent implements OnInit {

  @ViewChild('canvas', { static: true }) 
  public canvas!: ElementRef<HTMLCanvasElement>;

  @ViewChild('railSlider', { static: true }) 
  public railSlider!: MatSlider;

  public pointA: Point = {
    x : null,
    y : null
  };
  public pointB: Point = {
    x : null,
    y : null
  };
  public pointC: Point = {
    x : null,
    y : null
  };
  public pointD: Point = {
    x : null,
    y : null
  };

  public sidelinePointA: Point = {
    x : null,
    y : null
  };
  public sidelinePointB: Point = {
    x : null,
    y : null
  };

  public varLinePointA: Point = {
    x : null,
    y : null
  };
  public varLinePointB: Point = {
    x : null,
    y : null
  };

  public mouseX !: number;
  public mouseY !: number;

  public isThereImage : boolean = false;
  public isDownloadable : boolean = false;

  public direction : string = '';
  public directionIcon : string = '';
  public point : string = '';

  public ctx !: CanvasRenderingContext2D|null;

  public angleWithSidelineA : number|null = null;
  public angleWithSidelineB : number|null = null;
  public angleWithSidelineC : number|null = null;
  public angleDiff : number|null = null;

  public sidelineCount : number = 0;
  public gamezoneCount : number = 0;

  public isGamezoneDone : boolean = false;
  public isSidelineDone : boolean = false;
  
  public sliderValue : number = 0;

  public isRailGoingRight : boolean|null = null;
  public canMaterialize : boolean = false;
  public image! : HTMLImageElement;
  public downloadURL! : string;

  public lastPoint:string[] = [];
  public pointList : Point[] = [];

  public finalState : boolean = false;
  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    console.log(this.isThereImage);
    console.log("isDownloadable : " + this.isDownloadable);
  }

  loadImage(e:any){
    var width;
    var height;
    this.image = new Image();
    this.image.src = URL.createObjectURL(e.target.files[0]);
    this.image.onload = () => {
      width = this.image.width;
      height = this.image.height;
      this.canvas.nativeElement.width = this.image.width;
      this.canvas.nativeElement.height = this.image.height;
      if(this.isThereImage){
        this.ctx!.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.ctx!.drawImage(this.image,0,0, this.image.width, this.image.height);
        this.isThereImage = true;
        this.canvas.nativeElement.style.visibility = "visible";
        console.log(this.isThereImage);
      }else{
        this.ctx!.drawImage(this.image,0,0, this.image.width, this.image.height);
        this.isThereImage = true;
        this.canvas.nativeElement.style.visibility = "visible";
        console.log(this.isThereImage);
      } 
      
      
    }
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  changeDirection(direction : string){
    if(direction == 'left'){
      this.direction =  'right to left';
      this.directionIcon = 'arrow_back';

    }else if(direction == 'right'){
      this.direction = 'left to right';
      this.directionIcon = 'arrow_forward';
    }
    
  }

  selectPoint(point : string){
    this.point =  point;
    console.log('You choose point : ' + this.point);
  }

  pointPlacement(e:MouseEvent) {
    console.log(e.clientX);
    console.log(e.clientY);
    let rect = this.canvas!.nativeElement.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    x = Math.round(x);
    y = Math.round(y);
    switch(this.point){
      case "Point1":
        if(this.gamezoneCount < 4){
          this.lastPoint.push(this.point);
          this.drawCircle(x,y);
          this.pointA.x = x;
          this.pointA.y = y;
          this.gamezoneCount++;
          this.pointList.push(this.pointA);
          console.log(this.pointList);
          console.log(this.lastPoint);
        }
        break;
      case "Point2":
        if(this.gamezoneCount < 4){
          this.lastPoint.push(this.point);
          this.drawCircle(x,y);
          this.pointB.x = x;
          this.pointB.y = y;
          this.gamezoneCount++;
          this.pointList.push(this.pointB);
          console.log(this.pointList);
          console.log(this.lastPoint);
        }
        break;
      case "Point3":
        if(this.gamezoneCount < 4){
          this.lastPoint.push(this.point);
          this.drawCircle(x,y);
          this.pointC.x = x;
          this.pointC.y = y;
          this.gamezoneCount++;
          this.pointList.push(this.pointC);
          console.log(this.pointList);
          console.log(this.lastPoint);
        }
        
        break;
      case "Point4":
        if(this.gamezoneCount < 4){
          this.lastPoint.push(this.point);
          this.drawCircle(x,y);
          this.pointD.x = x;
          this.pointD.y = y;
          this.gamezoneCount++;
          this.pointList.push(this.pointD);
          console.log(this.pointList);
          console.log(this.lastPoint);
        }
        
        break;
      case "SidelinePoint1":
        if(this.sidelineCount < 2){
          this.lastPoint.push(this.point);
          this.drawCircle(x,y);
          this.sidelinePointA.x = x;
          this.sidelinePointA.y = y;
          this.sidelineCount++;
          this.pointList.push(this.sidelinePointA);
          console.log(this.pointList);
          console.log(this.lastPoint);
        }
        break;
      case "SidelinePoint2":
        if(this.sidelineCount < 2){
          this.lastPoint.push(this.point);
          this.drawCircle(x,y);
          this.sidelinePointB.x = x;
          this.sidelinePointB.y = y;
          this.sidelineCount++;
          this.pointList.push(this.sidelinePointB);
          console.log(this.pointList);
          console.log(this.lastPoint);
        }
        break;
    }
    this.check(this.pointList);
    let sidelineRes = this.checkSidelineState();
    let gameZoneRes = this.checkGameZoneState();

    if(sidelineRes == 1 && this.isSidelineDone != true){
      this.isSidelineDone = true;
    }
    if(gameZoneRes == 1 && this.isGamezoneDone != true){
      this.isGamezoneDone = true;
    }

    if(sidelineRes == 1 && gameZoneRes == 1){

      if(this.pointC.x!>this.pointA.x!){
        let difX = this.pointC.x!-this.pointA.x!;
        this.varLinePointA.x = this.pointC.x! - (difX/2);
      }else if(this.pointA.x!>this.pointC.x!){
        let difX =this.pointA.x!-this.pointC.x!;
        this.varLinePointA.x = this.pointA.x! - (difX/2);
      }else{
        this.varLinePointA.x = this.pointA.x;
      }

      if(this.pointD.x!>this.pointB.x!){
        let difX = this.pointD.x!-this.pointB.x!;
        this.varLinePointB.x = this.pointD.x!- (difX/2);
      }else if(this.pointB.x!>this.pointD.x!){
        let difX = this.pointB.x!-this.pointD.x!;
        this.varLinePointB.x = this.pointB.x!-(difX/2);
      }else{
        this.varLinePointB.x = this.pointB.x;
      }
      
      if(this.pointC.y!>this.pointA.y!){
        let difY = this.pointC.y!-this.pointA.y!;
        this.varLinePointA.y = this.pointC.y! - (difY/2);
      }else if(this.pointA.y!>this.pointC.y!){
        let difY =this.pointA.y!-this.pointC.y!;
        this.varLinePointA.y = this.pointA.y! - (difY/2);
      }else{
        this.varLinePointA.y = this.pointA.y!;
      }

      if(this.pointD.y!>this.pointB.y!){
        let difY =this.pointD.y!-this.pointB.y!;
        this.varLinePointB.y = this.pointD.y! - (difY/2);
      }else if(this.pointB.y!>this.pointD.y!){
        let difY =this.pointB.y!-this.pointD.y!;
        this.varLinePointB.y= this.pointB.y! - (difY/2);
      }else{
        this.varLinePointB.y = this.pointB.y!;
      }
      this.canMaterialize = true;
      this.sliderValue = this.varLinePointA.x!;
      this.drawCircle(this.varLinePointA.x!, this.varLinePointA.y!);
      this.drawCircle(this.varLinePointB.x!, this.varLinePointB.y!);
      this.calculateAngleWithSideline();
      this.drawVarLine(this.varLinePointA,this.varLinePointB);
      
      this.materializeRail();
      
    }
  }

  //DRAW METHODS
  drawCircle(x:number,y:number){
    if(x != null && y!=null){
      this.ctx!.beginPath();
      this.ctx!.arc(x, y, 4, 0, 2 * Math.PI, false);
      this.ctx!.closePath();
      this.ctx!.fill();
    }
  }

  drawLine(point1 : Point, point2:Point){
    this.ctx!.lineWidth = 1;
    this.ctx!.strokeStyle = '#000000';
    this.ctx!.beginPath(); 
    this.ctx!.moveTo(point1.x!,point1.y!);
    this.ctx!.lineTo(point2.x!,point2.y!);
    this.ctx!.stroke();
  }

  drawVarLine(point1 : Point, point2:Point){
    this.ctx!.lineWidth = 2;
    this.ctx!.strokeStyle = '#ff0000';
    this.ctx!.beginPath(); 
    this.ctx!.moveTo(point1.x!,point1.y!);
    this.ctx!.lineTo(point2.x!,point2.y!);
    this.ctx!.stroke();
  }

  //ANGLE CALCULATION 
  calculateAngleWithSideline(){
    let dAa = ((this.pointB.y! - this.pointA.y!)/(this.pointB.x! - this.pointA.x!));
    let dBa = ((this.pointD.y! - this.pointC.y!)/(this.pointD.x! - this.pointC.x!));
    let dCa = ((this.varLinePointB.y! - this.varLinePointA.y!)/(this.varLinePointB.x! - this.varLinePointA.x!));

    let dSa = ((this.sidelinePointB.y! - this.sidelinePointA.y!)/(this.sidelinePointB.x! - this.sidelinePointA.x!));

    let angleA = Math.atan(((dAa-dSa)/(1+(dAa*dSa))));
    if(angleA < 0) {angleA = angleA * -1;}

    let angleB = Math.atan(((dBa-dSa)/(1+(dBa*dSa))));
    if(angleB < 0) {angleB = angleB * -1;}

    let angleC = Math.atan(((dCa-dSa)/(1+(dCa*dSa))));
    if(angleC < 0) {angleC = angleC * -1;}

    this.angleWithSidelineA = Math.round((angleA * (180/Math.PI))*10)/10;
    this.angleWithSidelineB = Math.round((angleB * (180/Math.PI))*10)/10;
    this.angleWithSidelineC = Math.round((angleC * (180/Math.PI))*10)/10;
  }

  //METHODS TO CHECK IF ALL POINTS ARE SET UP
  checkSidelineState():number{
    let res : number;
    if(this.sidelinePointA.x&& this.sidelinePointB.y){
      res = 1;
    }else{
      res = 0;
    }

    return res;
  }

  checkGameZoneState():number{
    let res : number;
    if(this.pointA.x && this.pointB.y && this.pointC.x && this.pointD.y){
      res = 1;
    }else{
      res = 0;
    }

    return res;
  }

  materializeRail(){
    const canvas = this.ctx!.canvas;
    const mainDiv = document.getElementById("main");
    if(this.canMaterialize == true){
      setInterval(() => {
        this.ctx!.clearRect(0, 0, canvas.width, canvas.height);      
        this.redraw(this.pointList);
        this.check(this.pointList);
      }, 1);    
    }
  }

  varLineMove(e:MatSliderChange){

    let xA = this.formula(this.pointA.x!, this.pointC.x!, e.value!);
    let xB = this.formula(this.pointB.x!, this.pointD.x!, e.value!);

    var yA = this.formula(this.pointA.y!, this.pointC.y!, e.value!);
    var yB = this.formula(this.pointB.y!, this.pointD.y!, e.value!);
    
    
    

    if(xA !>this.varLinePointA.x!){
      this.isRailGoingRight = true;
    }else{
      this.isRailGoingRight = false;
    } 
    this.varLinePointA.x = xA;
    this.varLinePointB.x = xB;
    this.varLinePointA.y = yA;
    this.varLinePointB.y = yB;
    this.calculateAngleWithSideline();
  }

  redraw(pointList : Point[]){
    this.ctx!.drawImage(this.image,0,0, this.image.width, this.image.height);

    for(var i=0 ;i<pointList.length;i++){
      if(!this.finalState){
        this.drawCircle(pointList[i].x!, pointList[i].y!);
      }
      
    }
    if(this.checkGameZoneState() && this.checkSidelineState()){
      this.pointList.push(this.varLinePointA!, this.varLinePointB!);
      this.drawVarLine(this.varLinePointA!, this.varLinePointB!);
    }
  }

  formula(a:number, b:number, value:number):number {
    if(a>b){
      return (((value/100)*(b- a))+a);
    }else{
      return (((value/100)*(b-a))+a);
    }
  }

  finalVAR(){
    this.pointList = [
      this.varLinePointA,
      this.varLinePointB
    ]

    this.finalState = true;

  }

  saveCanvas(){
    this.finalVAR(); //Keep only VAR LINE
    this.ctx!.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height); 
    this.ctx!.drawImage(this.image,0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height); //Redraw the image after clear
    this.canvas.nativeElement.height = this.canvas.nativeElement.height + 75; //Add space for game direction
    // Set text parameters 
    this.ctx!.font = "40px Serif";
    this.ctx!.textAlign = "center";
    this.ctx!.fillText(
      "Sens du jeu : "+ this.direction, 
      this.canvas.nativeElement.width/2,
      this.canvas.nativeElement.height-35);//Add game direction text to the image
    this.redraw(this.pointList);//Redraw point and line 
    this.downloadURL = this.canvas.nativeElement.toDataURL();  //Generate download url
    this.isDownloadable = true;
  }

  download(){
    var a = document.createElement('a');
    a.href = this.downloadURL;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } 

  reset(){
    window.location.reload();
    //Restart everything
  }

  undo(){
    switch(this.lastPoint[this.lastPoint.length-1]){
      case "Point1":
        if(this.pointA.x !=null|| this.pointA.y!=null){
          var newPointList = this.pointList.filter(value=>value!=this.pointA);
          this.pointList = newPointList;
          var newLastPointList = this.lastPoint.filter(value=>value!=this.lastPoint[this.lastPoint.length-1]);
          this.lastPoint = newLastPointList;
          console.log(this.pointList);
          this.gamezoneCount--; 
        }
        break;
      case "Point2":
        if(this.pointB.x !=null|| this.pointB.y!=null){
          var newPointList = this.pointList.filter(value=>value!=this.pointB);
          this.pointList = newPointList;
          var newLastPointList = this.lastPoint.filter(value=>value!=this.lastPoint[this.lastPoint.length-1]);
          this.lastPoint = newLastPointList;
          console.log(this.pointList);
          this.gamezoneCount--; 
        }
        break;
      case "Point3":
        if(this.pointC.x !=null|| this.pointC.y!=null){
          var newPointList = this.pointList.filter(value=>value!=this.pointC);
          this.pointList = newPointList;
          var newLastPointList = this.lastPoint.filter(value=>value!=this.lastPoint[this.lastPoint.length-1]);
          this.lastPoint = newLastPointList;
          console.log(this.pointList);
          this.gamezoneCount--; 
        }
        break;
      case "Point4":
        if(this.pointD.x !=null|| this.pointD.y!=null){
          var newPointList = this.pointList.filter(value=>value!=this.pointD);
          this.pointList = newPointList;
          var newLastPointList = this.lastPoint.filter(value=>value!=this.lastPoint[this.lastPoint.length-1]);
          this.lastPoint = newLastPointList;
          console.log(this.pointList);
          this.gamezoneCount--; 
        }
        break;
      case "SidelinePoint1":
        if(this.sidelinePointA.x !=null|| this.sidelinePointA.y!=null){
          var newPointList = this.pointList.filter(value=>value!=this.sidelinePointA);
          this.pointList = newPointList;
          var newLastPointList = this.lastPoint.filter(value=>value!=this.lastPoint[this.lastPoint.length-1]);
          this.lastPoint = newLastPointList;
          console.log(this.pointList);
          this.sidelineCount--; 
        }
        break;
      case "SidelinePoint2":
        if(this.sidelinePointB.x !=null|| this.sidelinePointB.y!=null){
          var newPointList = this.pointList.filter(value=>value!=this.sidelinePointB);
          this.pointList = newPointList;
          var newLastPointList = this.lastPoint.filter(value=>value!=this.lastPoint[this.lastPoint.length-1]);
          this.lastPoint = newLastPointList;
          console.log(this.pointList);
          this.sidelineCount--; 
        }
        break;
    }

    
    if(this.sidelineCount!=2){
      var newPointList = this.pointList.filter(value=>value!=this.varLinePointA&&value!=this.varLinePointB);
      this.pointList = newPointList;
    }
    this.ctx!.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);      
    this.redraw(this.pointList);

    this.check(this.pointList);
  }

  check(pL:Point[]){
    if(pL.find(value=>value==this.pointA) && pL.find(value=>value==this.pointB)){
      this.drawLine(pL.find(value=>value==this.pointA)!,pL.find(value=>value==this.pointB)!);
    }

    if(pL.find(value=>value==this.pointB) && pL.find(value=>value==this.pointD)){
      this.drawLine(pL.find(value=>value==this.pointB)!,pL.find(value=>value==this.pointD)!);
    }

    if(pL.find(value=>value==this.pointD) && pL.find(value=>value==this.pointC)){
      this.drawLine(pL.find(value=>value==this.pointD)!,pL.find(value=>value==this.pointC)!);
    } 

    if(pL.find(value=>value==this.pointC) && pL.find(value=>value==this.pointA)){
      this.drawLine(pL.find(value=>value==this.pointC)!,pL.find(value=>value==this.pointA)!);
    }

    if(pL.find(value=>value==this.sidelinePointA) && pL.find(value=>value==this.sidelinePointB)){
      this.drawLine(pL.find(value=>value==this.sidelinePointA)!,pL.find(value=>value==this.sidelinePointB)!);
    }
  }
}
